import config from './config'
import { axiosInstance as axios } from './axiosInterceptor'
import {
  generateBudget as budget,
  generateSidebarSections,
  logsMock
} from './mirage/server'
import MockAdapter from 'axios-mock-adapter'

export const BASE_URL = config.apiServer
export const ESTIMATOR_BASE_URL = `${BASE_URL}/estimator`
export const ESTIMATOR_BASE_URL_V2 = `${BASE_URL}/estimator/v2`
export const AUTHENTIC_SERVER = process.env.REACT_APP_AUTHENTIC_SERVER ||
'https://auth.aiinkwell.app'

export const DEVELOPER_PS_URL = process.env.REACT_APP_DEVELOPER_PS_URL ||
  'https://staging-dot-ecco-aik-creator.uw.r.appspot.com'
export const STAGING_PS_URL = process.env.REACT_APP_STAGING_PS_URL ||
  'https://staging-dot-ecco-aik-creator.uw.r.appspot.com'
export const PROD_PS_URL = process.env.REACT_APP_PROD_PS_URL ||
  'https://ecco-aik-creator.uw.r.appspot.com'

function getUrl (endpoint, url = BASE_URL) {
  const mockEndpoints = ['generate-budget', 'generate-design']
  if (mockEndpoints.includes(endpoint)) {
    return `http://localhost:3000/${endpoint}`
  }
  return `${url}/${endpoint}`
}

const headers = () => {
  const tokenKey = '_aui_authToken'
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(tokenKey)}`
  }
}

// A common function to handle API requests with a loading state and error handling
export const fetchWithLoading = async (apiCall, setLoading, setError) => {
  try {
    setLoading(true)
    setError(null)
    const response = await apiCall()
    return response.data
  } catch (error) {
    setError(error)
    throw error
  } finally {
    setLoading(false)
  }
}

export const generateBudget = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const data = budget()
      resolve({ data })
    }, 3000)
  })
}

export const getBudget = async () => {
  const response = await axios.get(
    getUrl('budget', ESTIMATOR_BASE_URL),
    {
      headers: headers()
    }
  )
  return response.data
}

export const updateBudget = async (data) => {
  const response = await axios.post(
    getUrl('budget', ESTIMATOR_BASE_URL),
    {
      headers: headers(),
      data
    }
  )

  return response.data
}

export const generateDesign = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const data = {
        sidebarSections: generateSidebarSections()
      }
      resolve({ data })
    }, 3000)
  })
}

export const sendMessage = async (data) => {
  const response = await axios.post(
    getUrl(
      'send_message',
      ESTIMATOR_BASE_URL_V2
    ),
    data,
    {
      headers: headers()
    }
  )
  return response.data
}

export const checkMessageStatus = async (data) => {
  const { threadId } = data
  const response = await axios.get(
    getUrl(
      `message_status?thread_id=${threadId}`,
      ESTIMATOR_BASE_URL_V2
    ),
    {
      headers: headers()
    }
  )
  return response.data
}

export const retrieveMessages = async (data) => {
  const { threadId, limit, after } = data
  let queryParams = `thread_id=${threadId}`
  if (limit) {
    queryParams += `&limit=${limit}`
  }
  if (after) {
    queryParams += `&after=${after}`
  }

  return await axios.get(
    getUrl(`messages?${queryParams}`,
      ESTIMATOR_BASE_URL_V2
    ), {
      headers: headers()
    })
}

export const uploadFile = async (file, structure = '') => {
  const formData = new FormData()
  formData.append('file', file)

  const url = getUrl('upload_file', ESTIMATOR_BASE_URL_V2)
  formData.append('structure', structure)

  const response = await axios.post(
    url, formData, {
      headers: {
        ...headers(),
        'Content-Type': 'multipart/form-data'
      }
    })

  return response.data
}

export const summarize = async (data) => {
  const response = await axios.post(
    getUrl(
      'summarize',
      ESTIMATOR_BASE_URL
    ),
    {
      messages: data.messages,
      word_count: data.wordCount
    },
    { headers: headers() }
  )
  return response.data
}

export const getProjects = async () => {
  const response = await axios.get(
    getUrl('projects'),
    {
      headers: headers()
    }
  )
  return response.data
}

export const getProject = async (projectId) => {
  const response = await axios.get(
    getUrl(`projects/${projectId}`),
    {
      headers: headers()
    }
  )
  return response.data
}

export const saveProject = async (body) => {
  const response = await axios.post(
    getUrl('projects'),
    {
      ...body,
      stage: 'stage1',
      category: 'technology',
      confidence_level: 'medium'
    },
    {
      headers: headers()
    }
  )
  return response?.data
}

export const updateProject = async (projectId, body) => {
  const response = await axios.put(
    getUrl(`projects/${projectId}`),
    body,
    {
      headers: headers()
    }
  )
  return response.data
}

export const getLogs = async (length) => {
  return logsMock(length)
}

export const findUser = async () => {
  const response = await axios.get(
    getUrl('user/profile'),
    { headers: headers() }
  )
  return response.data
}

export const findOrCreateUser = async (name) => {
  const response = await axios.post(
    getUrl('user/profile'),
    { name },
    { headers: headers() }
  )
  return response.data
}

export const getWorldData = async () => {
  const response = await axios.get('/world.json')
  return response.data
}

export const getResortData = async () => {
  const response = await axios.get('/resort.json')
  return response.data
}

export const getParkData = async () => {
  const response = await axios.get('/park.json')
  return response.data
}

export const loadProjectJson = async () => {
  const response = await axios.get('/park.json')
  return response.data
}

const adapter = new MockAdapter(axios, { delayResponse: 1000 })

adapter.onGet(`${ESTIMATOR_BASE_URL}/budget`).reply(() => {
  return [200, budget()]
})
adapter.onGet(`${ESTIMATOR_BASE_URL_V2}/budget`).reply(() => {
  return [200, budget()]
})
adapter.onPost(`${ESTIMATOR_BASE_URL}/budget`).reply((config) => {
  const updatedBudget = JSON.parse(config.data)
  return [200, updatedBudget]
})

adapter.onPost(`${ESTIMATOR_BASE_URL_V2}/budget`).reply((config) => {
  const updatedBudget = JSON.parse(config.data)
  return [200, updatedBudget]
})
adapter.onAny().passThrough()
